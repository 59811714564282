import { mapHelper } from '@/utils/common.js';

// 模块
const logType = [
  { value: 'M', label: '服务端' },
  { value: 'U', label: '移动端' },
  { value: 'I', label: 'IOT' }
];
const { map: logTypeMap, setOps: setLogTypeOps } = mapHelper.setMap(logType);

export { logType, logTypeMap, setLogTypeOps };
