<template>
  <div class="log-list">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isManySelect="false"
      :isIndex="true"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <v-button type="danger" text="清理日志" @click="clearLogsOnClick" />
      </template>
      <template #searchSlot>
        <v-input label="操作人" v-model="searchParam.optUser" clearable placeholder="请输入操作人" />
        <v-select label="模块" v-model="searchParam.logType" clearable :options="logTypeOptions" placeholder="模块" />
        <v-date-picker
          label="操作时间"
          v-model="optTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
      </template>
    </v-list>

    <v-dialog title="清理日志" v-model="clearLogDiaShow" width="50%" :confirmLoading="clearLogDiaOkLoading" @confirm="clearLogDiaOnOk">
      <v-date-picker
        label="清理日志时间范围"
        v-model="clearLogTime"
        type="datetimerange"
        formatValue="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        startPlaceholder="开始时间"
        endPlaceholder="结束时间"
      />
    </v-dialog>
  </div>
</template>

<script>
import { getLogListUrl, deleteLogInfoUrl } from './api.js';
import { setLogTypeOps } from './map.js';
import { createHTMLVNode } from '@/utils/utils.js';
import { MessageBox } from 'element-ui';

export default {
  name: 'pubCapLogCenLogList',
  data() {
    return {
      logTypeOptions: setLogTypeOps(),
      logTypeMap: {
        M: '服务端',
        U: '移动端',
        I: 'IOT',
        S: '消息'
      },
      searchParam: {
        // tenantId: '',
        optUser: '',
        logType: '',
        startDate: '',
        endDate: ''
      },
      tableUrl: getLogListUrl,
      headers: [
        {
          prop: 'logType',
          label: '所属模块',
          align: 'center',
          width: 150,
          formatter: (row, prop) => {
            return this.logTypeMap[row[prop]];
          }
        },
        {
          prop: 'message',
          label: '操作内容',
          align: 'left',
          showOverflowTooltip: false,
          formatter: (row, prop) => {
            return createHTMLVNode(this, `<div>${row[prop] || row.exceptionName}</div>`, row, prop, {
              cb: (event, row, value) => {
                console.log('=> ', row.stackTrace.replace(/\t/g, '&nbsp;'));
                MessageBox.alert(`${row.stackTrace.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}`, '操作内容', {
                  dangerouslyUseHTMLString: true,
                  customClass: 'handle-content'
                });
              }
            });
          }
        },
        {
          prop: 'optUser',
          label: '操作人',
          width: 150,
          align: 'center'
        },
        {
          prop: 'createTime',
          label: '操作时间',
          width: 200,
          align: 'center'
        },
        {
          prop: 'remoteIp',
          label: 'IP地址',
          width: 200,
          align: 'center'
        }
      ],
      clearLogDiaShow: false,
      clearLogDiaOkLoading: false,
      clearLogTime: []
    };
  },
  computed: {
    optTime: {
      get() {
        return !this.searchParam.startDate && !this.searchParam.endDate ? [] : [this.searchParam.startDate, this.searchParam.endDate];
      },
      set(val) {
        [this.searchParam.startDate, this.searchParam.endDate] = val || ['', ''];
      }
    }
  },
  created() {
    // this.searchParam.tenantId = this.$store.state.app.userInfo.tenantId;
  },
  mounted() {},
  methods: {
    clearLogsOnClick() {
      this.clearLogTime = [];
      this.clearLogDiaShow = true;
    },
    clearLogDiaOnOk() {
      if (Array.isArray(this.clearLogTime) && this.clearLogTime.length === 2) {
        this.clearLogDiaOkLoading = true;
        const form = {
          // tenantId: this.$store.state.app.userInfo.tenantId,
          startDate: this.clearLogTime[0],
          endDate: this.clearLogTime[1]
        };
        this.$axios
          .post(deleteLogInfoUrl, form)
          .then(res => {
            if (res && res.code === 200) {
              this.$message.success(res.msg);
              this.$refs.list.search();
              this.clearLogDiaShow = false;
            }
            setTimeout(() => {
              this.clearLogDiaOkLoading = false;
            }, 300);
          })
          .catch(() => {
            this.clearLogDiaOkLoading = false;
          });
      } else {
        this.$message.info('请选择清理日志时间范围');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.log-list {
  box-sizing: border-box;
  height: 100%;
}
</style>

<style lang="less">
.el-message-box.handle-content {
  width: 1200px;
  max-width: 95vw;
  .el-message-box__content {
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
    overflow-y: auto;
    word-break: break-all;
  }
}
</style>
